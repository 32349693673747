import {createApp} from 'vue'

import App from './App.vue'
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import ApiService from "@/core/services/ApiService";
import "bootstrap";

import i18n from "@/core/plugins/i18n";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import {BaseService} from "@/core/services/BaseService";
import permission from "@/core/directives/permission";
import adminPermission from "@/core/directives/admin-permission";
import {Actions} from "@/store/enums/StoreEnums";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import codeValidation from './core/directives/code-validation';


const app = createApp(App)

BaseService._axios.interceptors.response.use((response) => {
  const status = response.status
  if (status === 201 || status === 202) {
    useToast().success('Save successfully', {position: 'top-right'})
  }
  return response
}, error => {
  const response = error.response;
  if (response.status === 422) {
    useToast().warning(response.data.message, {position: 'top-right'})
    store.dispatch(Actions.CREATE_FORM_ERROR, response.data.errors)
  }
  if (response.status === 404) {
    useToast().error(response.data.message, {position: 'top-right'})
    router.push({
      name: "404"
    });
  }
  if (response.status === 400) {
    useToast().error(response.data.message, {position: 'top-right'})
  }
  if (response.status >= 500) {
    useToast().error(response.data.message, {position: 'top-right'})
  }
  if (error.code === 'ECONNABORTED') {
    useToast().error("Request time out", {position: 'top-right'})
  }
  return Promise.reject(error.response)
})

BaseService._axios2.interceptors.response.use((response) => {
  const status = response.status
  if (status === 201 || status === 202) {
    useToast().success('Save successfully', {position: 'top-right'})
  }
  return response
}, error => {
  const response = error.response;
  if (response.status === 422) {
    useToast().warning(response.data.message, {position: 'top-right'})
    store.dispatch(Actions.CREATE_FORM_ERROR, response.data.errors)
  }
  if (response.status === 404) {
    useToast().error(response.data.message, {position: 'top-right'})
    router.push({
      name: "404"
    });
  }
  if (response.status === 400) {
    useToast().error(response.data.message, {position: 'top-right'})
  }
  if (response.status >= 500) {
    useToast().error(response.data.message, {position: 'top-right'})
  }
  if (error.code === 'ECONNABORTED') {
    useToast().error("Request time out", {position: 'top-right'})
  }
  return Promise.reject(error.response)
})

initInlineSvg(app);


const clickOutside = {
  mounted: (el, binding) => {
    el.clickOutsideEvent = event => {
      if (event.target.dataset.target === 'click') return;
      if (!el.contains(event.target)) {
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

app.directive("has-access", permission)
app.directive("code-validate", codeValidation)
app.directive("is-admin", adminPermission)
app.directive("click-outside", clickOutside)
app.use(router)
app.use(store)
app.use(ElementPlus);
app.use(i18n)

ApiService.init(app);
app.mount('#app')
